<template>
    <div class="actions">
        <a href="javascript:void(0)" class="btn btn-outline-primary btn-rounded btn-sm"><i class="las la-pen"></i></a>
        <a href="javascript:void(0)" class="btn btn-outline-danger btn-rounded btn-sm" @click.prevent="deleting"><i class="las la-times"></i></a>
    </div>
</template>
<script>
import {DELETE_MODEL} from '../../../graphql/host'
export default {
    props: {
        object: {type: Object, require: true}
    },
    data(){
        return {

        }
    },
    methods: {
        deleting(){
            this.$apollo.mutate({
                mutation: DELETE_MODEL,
                variables: {
					uid: this.object.uid
				},
                update: (store, { data: model }) => {
                    console.log(`Delete of model ${model}`)
				},
            })
        }
    },
    computed:{

    }
}
</script>
<style lang="scss" scoped>
    .actions{
        a{
            margin-left: 5px !important;
        }
    }
    
</style>